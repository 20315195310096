










import Vue from 'vue'
import { QRcode } from '../../model/entity/qrcode';
import { QRCodeDecorderServiceBuilder } from '../../services/QRCode/qrcodeDecorderServiceBuilder';

import store from '../../store/session';

export default Vue.extend({
    components: {
        QLoader: () => import('../../components/elements/QLoader.vue'),
    },
    mounted() {
        this.checkin();
    },
    computed: {
        code(): string {
            return this.$route.query.code;
        },
        isLoading(): boolean {
            return store.state.isLoading;
        },
    },
    methods: {
        checkin() {
            store.dispatch('init');
            store.dispatch('initCode', this.code);

            try {
                const data = QRCodeDecorderServiceBuilder.Instance().decode(this.code);
                if (data === null) {
                    throw new Error('読み取りコードが不正です');
                }

                store.dispatch('checkin', data.table_session_id)
                    .then((data) => {
                        this.$router.replace('/category');
                        store.dispatch('checkSession')
                            .then((status: string) => {
                                this.$router.push('/settle-complete');
                            }).catch(() => {
                                store.commit('isDone', false);
                            });
                    })
                    .catch((err) => {
                        const response = err.response || {};
                        const data = response.data || {};
                        const message = data.message || err.message;

                        this.$router.replace(`/error?message=${message}`);
                    });
            } catch (err) {
                this.$router.push(`/error?message=${err.message}`);
            }
        },
    },
})
