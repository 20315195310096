import { IQRCodeDecoderService } from './IQRCodeDecoderSerivce';
import { QRcode } from '@/model/entity/qrcode';
import * as base64 from '../../common/crypto/base64';

export class QRCodeDecorderService implements IQRCodeDecoderService {
    decode(code: string): QRcode {
        try {
            const decrypted = base64.decript(code);
            const data = JSON.parse(decrypted) as QRcode;

            return data;
        } catch (err) {
            console.error(err);

            return null;
        }
    }
}
