import Base64 from 'crypto-js/enc-base64';
import UTF8 from 'crypto-js/enc-utf8';

// URL safe な文字列に変換するために、記号を置き換える
const charmap = {
    '+': '_',
    '/': '-',
    '=': '.',
};

export const decript = (word: string): string => {
    let tmp = word;
    for (const char of Object.keys(charmap)) {
        tmp = tmp.replace(charmap[char], char);
    }

    return UTF8.stringify(Base64.parse(tmp));
}

export const encript = (word: string): string => {
    let tmp = Base64.stringify(UTF8.parse(word));
    for (const char of Object.keys(charmap)) {
        tmp = tmp.replace(char, charmap[char]);
    }

    return tmp
}
